import React from 'react';
import { Route, Switch } from 'react-router-dom';
import loadable from '@loadable/component';
import { useSelector } from 'react-redux';
import urls from './utils/urls';
import { RootState } from './rootReducer';
import AuthenticatedRoute from './AuthenticatedRoute';
import IncreaseForm from './pages/A/h-limit-increase/IncreaseForm';

const PatternRoutes = () => {
  const { viewPattern } = useSelector((state: RootState) => state.pattern);
  // Header
  const headerComponent = loadable(() => import(`./components/${viewPattern}/Header`).catch(() => import('./components/A/Header')));
  // Footer
  const footerComponent = loadable(() => import(`./components/${viewPattern}/Footer`).catch(() => import('./components/A/Footer')));
  // A
  const contractDetails = loadable(() => import(`./pages/${viewPattern}/a-transaction-history/ContractDetails`).catch(() => import('./pages/A/a-transaction-history/ContractDetails')));
  const downloadBasicContract = loadable(() => import(`./pages/${viewPattern}/a-transaction-history/DownloadBasicContract`).catch(() => import('./pages/A/a-transaction-history/DownloadBasicContract')));
  // B
  const confirmNextRepaymentDate = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/ConfirmNextRepaymentDate`).catch(() => import('./pages/A/b-repayment-plan/ConfirmNextRepaymentDate')));
  const repaymentScheduleInput = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/RepaymentScheduleInput`).catch(() => import('./pages/A/b-repayment-plan/RepaymentScheduleInput')));
  const repaymentScheduleConfirm = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/RepaymentScheduleConfirm`).catch(() => import('./pages/A/b-repayment-plan/RepaymentScheduleConfirm')));
  const repaymentScheduleComplete = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/RepaymentScheduleComplete`).catch(() => import('./pages/A/b-repayment-plan/RepaymentScheduleComplete')));
  const specificBlOrMultipleAccountScreen = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/SpecificBlOrMultipleAccountScreen`).catch(() => import('./pages/A/b-repayment-plan/SpecificBlOrMultipleAccountScreen')));
  const noBalanceScreen = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/NoBalanceScreen`).catch(() => import('./pages/A/b-repayment-plan/NoBalanceScreen')));
  const payoffSimulation = loadable(() => import(`./pages/${viewPattern}/b-repayment-plan/PayoffSimulation`).catch(() => import('./pages/A/b-repayment-plan/PayoffSimulation')));
  // C
  const updateContractDateInput = loadable(() => import(`./pages/${viewPattern}/c-contract-date/UpdateContractDateInput`).catch(() => import('./pages/A/c-contract-date/UpdateContractDateInput')));
  const updateContractDateConfirm = loadable(() => import(`./pages/${viewPattern}/c-contract-date/UpdateContractDateConfirm`).catch(() => import('./pages/A/c-contract-date/UpdateContractDateConfirm')));
  const updateContractDateComplete = loadable(() => import(`./pages/${viewPattern}/c-contract-date/UpdateContractDateComplete`).catch(() => import('./pages/A/c-contract-date/UpdateContractDateComplete')));
  const contractNotAvailable = loadable(() => import(`./pages/${viewPattern}/c-contract-date/NotAvailable`).catch(() => import('./pages/A/c-contract-date/NotAvailable')));
  const contractContinuationConfirm = loadable(() => import(`./pages/${viewPattern}/c-contract-date/ContractContinuationConfirm`).catch(() => import('./pages/A/c-contract-date/ContractContinuationConfirm')));
  const littleLateRepayment = loadable(() => import(`./pages/${viewPattern}/c-contract-date/LittleLateRepayment`).catch(() => import('./pages/A/c-contract-date/LittleLateRepayment')));
  const lateRepaymentMoreThan11days = loadable(() => import(`./pages/${viewPattern}/c-contract-date/LateRepaymentMoreThan11days`).catch(() => import('./pages/A/c-contract-date/LateRepaymentMoreThan11days')));
  const contractReturnDeposit = loadable(() => import(`./pages/${viewPattern}/c-contract-date/ReturnDeposit`).catch(() => import('./pages/A/c-contract-date/ReturnDeposit')));
  // T
  const top = loadable(() => import(`./pages/${viewPattern}/t-top/Top`).catch(() => import('./pages/A/t-top/Top')));
  const informationDetails = loadable(() => import(`./pages/${viewPattern}/t-top/InformationDetails`).catch(() => import('./pages/A/t-top/InformationDetails')));
  // D
  const screenInput = loadable(() => import(`./pages/${viewPattern}/d-web-details/ScreenInput`).catch(() => import('./pages/A/d-web-details/ScreenInput')));
  const screenConfirmation = loadable(() => import(`./pages/${viewPattern}/d-web-details/ScreenConfirmation`).catch(() => import('./pages/A/d-web-details/ScreenConfirmation')));
  const screenCompletion = loadable(() => import(`./pages/${viewPattern}/d-web-details/ScreenCompletion`).catch(() => import('./pages/A/d-web-details/ScreenCompletion')));
  const screenDownload = loadable(() => import(`./pages/${viewPattern}/d-web-details/ScreenDownload`).catch(() => import('./pages/A/d-web-details/ScreenDownload')));
  const sendUsageDetailsInput = loadable(() => import(`./pages/${viewPattern}/d-web-details/SendUsageDetailsInput`).catch(() => import('./pages/A/d-web-details/SendUsageDetailsInput')));
  const sendUsageDetailsConfirm = loadable(() => import(`./pages/${viewPattern}/d-web-details/SendUsageDetailsConfirm`).catch(() => import('./pages/A/d-web-details/SendUsageDetailsConfirm')));
  const sendUsageDetailsComplete = loadable(() => import(`./pages/${viewPattern}/d-web-details/SendUsageDetailsComplete`).catch(() => import('./pages/A/d-web-details/SendUsageDetailsComplete')));
  const downloadAfterUnlocking = loadable(() => import(`./pages/${viewPattern}/d-web-details/DownloadAfterUnlocking`).catch(() => import('./pages/A/d-web-details/DownloadAfterUnlocking')));
  const downloadAfterCancellation = loadable(() => import(`./pages/${viewPattern}/d-web-details/DownloadAfterCancellation`).catch(() => import('./pages/A/d-web-details/DownloadAfterCancellation')));
  // E
  const borrowingInput = loadable(() => import(`./pages/${viewPattern}/e-loan-application/BorrowingInput`).catch(() => import('./pages/A/e-loan-application/BorrowingInput')));
  const borrowingConfirm = loadable(() => import(`./pages/${viewPattern}/e-loan-application/BorrowingConfirm`).catch(() => import('./pages/A/e-loan-application/BorrowingConfirm')));
  const borrowingComplete = loadable(() => import(`./pages/${viewPattern}/e-loan-application/BorrowingComplete`).catch(() => import('./pages/A/e-loan-application/BorrowingComplete')));
  const unregisteredAccountError = loadable(() => import(`./pages/${viewPattern}/e-loan-application/UnregisteredAccountError`).catch(() => import('./pages/A/e-loan-application/UnregisteredAccountError')));
  const requested = loadable(() => import(`./pages/${viewPattern}/e-loan-application/Requested`).catch(() => import('./pages/A/e-loan-application/Requested')));
  const usageLimitTenThousandOrLess = loadable(() => import(`./pages/${viewPattern}/e-loan-application/UsageLimitTenThousandOrLess`).catch(() => import('./pages/A/e-loan-application/UsageLimitTenThousandOrLess')));
  const lateRepayment = loadable(() => import(`./pages/${viewPattern}/e-loan-application/LateRepayment`).catch(() => import('./pages/A/e-loan-application/LateRepayment')));
  const repaymentSimulation = loadable(() => import(`./pages/${viewPattern}/e-loan-application/RepaymentSimulation`).catch(() => import('./pages/A/e-loan-application/RepaymentSimulation')));
  const depositExists = loadable(() => import(`./pages/${viewPattern}/e-loan-application/DepositExists`).catch(() => import('./pages/A/e-loan-application/DepositExists')));
  // F
  const transferReservationInput = loadable(() => import(`./pages/${viewPattern}/f-loan-application-inquiry/TransferReservationInput`).catch(() => import('./pages/A/f-loan-application-inquiry/TransferReservationInput')));
  const transferReservationConfirm = loadable(() => import(`./pages/${viewPattern}/f-loan-application-inquiry/TransferReservationConfirm`).catch(() => import('./pages/A/f-loan-application-inquiry/TransferReservationConfirm')));
  const transferReservationComplete = loadable(() => import(`./pages/${viewPattern}/f-loan-application-inquiry/TransferReservationComplete`).catch(() => import('./pages/A/f-loan-application-inquiry/TransferReservationComplete')));
  const cannotBeCanceled = loadable(() => import(`./pages/${viewPattern}/f-loan-application-inquiry/CannotBeCanceled`).catch(() => import('./pages/A/f-loan-application-inquiry/CannotBeCanceled')));
  // G
  const immediateShakingRegisteredInput = loadable(() => import(`./pages/${viewPattern}/g-account-registration/ImmediateShakingInput`).catch(() => import('./pages/A/g-account-registration/ImmediateShakingInput')));
  const immediateShakingRegisteredConfirm = loadable(() => import(`./pages/${viewPattern}/g-account-registration/ImmediateShakingConfirm`).catch(() => import('./pages/A/g-account-registration/ImmediateShakingConfirm')));
  const immediateShakingRegisteredComplete = loadable(() => import(`./pages/${viewPattern}/g-account-registration/ImmediateShakingComplete`).catch(() => import('./pages/A/g-account-registration/ImmediateShakingComplete')));
  // H
  const increaseTop = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/IncreaseTop`).catch(() => import('./pages/A/h-limit-increase/IncreaseTop')));
  const examinationErrorOffhours = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationError`).catch(() => import('./pages/A/h-limit-increase/ExaminationErrorOffhours')));
  const examinationNg = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationNg`).catch(() => import('./pages/A/h-limit-increase/ExaminationNg')));
  const examinationNgResisterMailAddressComplete = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/IncreaseLimitNoticeRequestComplete`).catch(() => import('./pages/A/h-limit-increase/ExaminationNgResisterMailAddressComplete')));
  const submitIncomeCertificate = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/SubmitIncomeCertificate`).catch(() => import('./pages/A/h-limit-increase/SubmitIncomeCertificate')));
  const increaseExaminationConfirm = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/IncreaseExaminationConfirm`).catch(() => import('./pages/A/h-limit-increase/IncreaseExaminationConfirm')));
  const workPlaceChangeRequest = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/WorkPlaceChangeRequest`).catch(() => import('./pages/A/h-limit-increase/WorkPlaceChangeRequest')));
  const changeContractInput = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ChangeContractInput`).catch(() => import('./pages/A/h-limit-increase/ChangeContractInput')));
  const examinationErrorResult = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ConfirmationOfIntention`).catch(() => import('./pages/A/h-limit-increase/ExaminationErrorResult')));
  const changeContractConfirm = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ChangeContractConfirm`).catch(() => import('./pages/A/h-limit-increase/ChangeContractConfirm')));
  const changeContractComplete = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ChangeContractComplete`).catch(() => import('./pages/A/h-limit-increase/ChangeContractComplete')));
  const interestRateFixedAdditionalInput = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/InterestRateFixedAdditionalInput`).catch(() => import('./pages/A/h-limit-increase/InterestRateFixedAdditionalInput')));
  const limitAndInterestRateChangeInput = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/LimitAndInterestRateChangeInput`).catch(() => import('./pages/A/h-limit-increase/LimitAndInterestRateChangeInput')));
  const interestRateChangeAdditionalInput = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/InterestRateChangeAdditionalInput`).catch(() => import('./pages/A/h-limit-increase/InterestRateChangeAdditionalInput')));
  const limitAndInterestRateChangeConfirm = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/LimitAndInterestRateChangeConfirm`).catch(() => import('./pages/A/h-limit-increase/LimitAndInterestRateChangeConfirm')));
  const interestRateChangeConfirm = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/InterestRateChangeConfirm`).catch(() => import('./pages/A/h-limit-increase/InterestRateChangeConfirm')));
  const interestRateChangeCompleteBorrowable = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/InterestRateChangeCompleteBorrowable`).catch(() => import('./pages/A/h-limit-increase/InterestRateChangeCompleteBorrowable')));
  const limitAndInterestRateChangeComplete = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/LimitAndInterestRateChangeComplete`).catch(() => import('./pages/A/h-limit-increase/LimitAndInterestRateChangeComplete')));
  const examinationResultPassed = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultPassed`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultPassed')));
  const examinationResultPassedIncomeCertificateSelection = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultPassedIncomeCertificateSelection`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultPassedIncomeCertificateSelection')));
  const examinationResultPassedInquiryInformation = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultPassedInquiryInformation`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultPassedInquiryInformation')));
  const increaseContractDateChangeInformation = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/IncreaseContractDateChangeInformation`).catch(() => import('./pages/A/h-limit-increase/IncreaseContractDateChangeInformation')));
  const examinationResultOutOfService = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultOutOfService`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultOutOfService')));
  const examinationResultWaitingForChange = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultWaitingForChange`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultWaitingForChange')));
  const examinationResultPassedCanBeConsidered = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultPassedCanBeConsidered`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultPassedCanBeConsidered')));
  const examinationResultPassedIncreaseAndInteresRate = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ExaminationResultPassedIncreaseAndInteresRate`).catch(() => import('./pages/A/h-limit-increase/ExaminationResultPassedIncreaseAndInteresRate')));
  const changeContractInputAnother = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/ChangeContractInputAnother`).catch(() => import('./pages/A/h-limit-increase/ChangeContractInputAnother')));
  const limitAndInterestRateChangeInputAnother = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/LimitAndInterestRateChangeInputAnother`).catch(() => import('./pages/A/h-limit-increase/LimitAndInterestRateChangeInputAnother')));
  const increaseForm = loadable(() => import(`./pages/${viewPattern}/h-limit-increase/IncreaseTop`).catch(() => import('./pages/A/h-limit-increase/IncreaseForm')));
  // I
  const webRepaymentInput = loadable(() => import(`./pages/${viewPattern}/i-web-repayment/WebRepaymentInput`).catch(() => import('./pages/A/i-web-repayment/WebRepaymentInput')));
  const webRepaymentConfirm = loadable(() => import(`./pages/${viewPattern}/i-web-repayment/WebRepaymentConfirm`).catch(() => import('./pages/A/i-web-repayment/WebRepaymentConfirm')));
  const handedOverToFinancialInstitutions = loadable(() => import(`./pages/${viewPattern}/i-web-repayment/HandedOverToFinancialInstitutions`).catch(() => import('./pages/A/i-web-repayment/HandedOverToFinancialInstitutions')));
  const noWebRepaymentBalance = loadable(() => import(`./pages/${viewPattern}/i-web-repayment/NoWebRepaymentBalance`).catch(() => import('./pages/A/i-web-repayment/NoWebRepaymentBalance')));
  const webRepaymentMaintenance = loadable(() => import(`./pages/${viewPattern}/i-web-repayment/WebRepaymentMaintenance`).catch(() => import('./pages/A/i-web-repayment/WebRepaymentMaintenance')));
  // J
  const automaticWithdrawalRegistration = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/AutomaticWithdrawalRegistration`).catch(() => import('./pages/A/j-account-transfer/AutomaticWithdrawalRegistration')));
  const confirmationOfWithdrawalDate = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/ConfirmationOfWithdrawalDate`).catch(() => import('./pages/A/j-account-transfer/ConfirmationOfWithdrawalDate')));
  const automaticWithdrawalApplicationAccountRegistration = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/AutomaticWithdrawalApplicationAccountRegistration`).catch(() => import('./pages/A/j-account-transfer/AutomaticWithdrawalApplicationAccountRegistration')));
  const mouthRegistrationComplete = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/MouthRegistrationComplete`).catch(() => import('./pages/A/j-account-transfer/MouthRegistrationComplete')));
  const mouthRegistrationNotComplete = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/MouthRegistrationNotComplete`).catch(() => import('./pages/A/j-account-transfer/MouthRegistrationNotComplete')));
  const mouthRegistered = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/MouthRegistered`).catch(() => import('./pages/A/j-account-transfer/MouthRegistered')));
  const automaticWithdrawalChangeInput = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/AutomaticWithdrawalChangeInput`).catch(() => import('./pages/A/j-account-transfer/AutomaticWithdrawalChangeInput')));
  const automaticWithdrawalDummy = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/AutomaticWithdrawalDummy`).catch(() => import('./pages/A/j-account-transfer/AutomaticWithdrawalDummy')));
  const confirmationOfCancellationOfMouthShakeRegistration = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/ConfirmationOfCancellationOfMouthShakeRegistration`).catch(() => import('./pages/A/j-account-transfer/ConfirmationOfCancellationOfMouthShakeRegistration')));
  const mouthRegistrationCancellationCompleted = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/MouthRegistrationCancellationCompleted`).catch(() => import('./pages/A/j-account-transfer/MouthRegistrationCancellationCompleted')));
  const registrationNotPossibleOutOfServiceCustomers = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/RegistrationNotPossibleOutOfServiceCustomers`).catch(() => import('./pages/A/j-account-transfer/RegistrationNotPossibleOutOfServiceCustomers')));
  const registrationNotPossibleOneDayDelay = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/RegistrationNotPossibleOneDayDelay`).catch(() => import('./pages/A/j-account-transfer/RegistrationNotPossibleOneDayDelay')));
  const registrationNotPossibleElevenDayDelay = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/RegistrationNotPossibleElevenDayDelay`).catch(() => import('./pages/A/j-account-transfer/RegistrationNotPossibleElevenDayDelay')));
  const registrationNotPossibleClaiming = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/RegistrationNotPossibleClaiming`).catch(() => import('./pages/A/j-account-transfer/RegistrationNotPossibleClaiming')));
  const RegistrationNotEnough = loadable(() => import(`./pages/${viewPattern}/j-account-transfer/RegistrationNotEnough`).catch(() => import('./pages/A/j-account-transfer/RegistrationNotEnough')));

  // K
  const confirmationOfCustomerInformation = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ConfirmationOfCustomerInformation`).catch(() => import('./pages/A/k-customer-attributes/ConfirmationOfCustomerInformation')));
  const confirmationOfCustomerInformationComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ConfirmationOfCustomerInformationComplete`).catch(() => import('./pages/A/k-customer-attributes/ConfirmationOfCustomerInformationComplete')));
  const emailAddressChangeInput = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/EmailAddressChangeInput`).catch(() => import('./pages/A/k-customer-attributes/EmailAddressChangeInput')));
  const emailAddressChangeConfirm = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/EmailAddressChangeConfirm`).catch(() => import('./pages/A/k-customer-attributes/EmailAddressChangeConfirm')));
  const emailAddressChangeComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/EmailAddressChangeComplete`).catch(() => import('./pages/A/k-customer-attributes/EmailAddressChangeComplete')));
  const changeAddressPhoneNumberInput = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeAddressPhoneNumberInput`).catch(() => import('./pages/A/k-customer-attributes/ChangeAddressPhoneNumberInput')));
  const changeAddressPhoneNumberConfirm = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeAddressPhoneNumberConfirm`).catch(() => import('./pages/A/k-customer-attributes/ChangeAddressPhoneNumberConfirm')));
  const changeAddressPhoneNumberComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeAddressPhoneNumberComplete`).catch(() => import('./pages/A/k-customer-attributes/ChangeAddressPhoneNumberComplete')));
  const changeOfWorkPlaceInput = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeOfWorkPlaceInput`).catch(() => import('./pages/A/k-customer-attributes/ChangeOfWorkPlaceInput')));
  const changeOfWorkPlaceConfirm = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeOfWorkPlaceConfirm`).catch(() => import('./pages/A/k-customer-attributes/ChangeOfWorkPlaceConfirm')));
  const changeOfWorkPlaceComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeOfWorkPlaceComplete`).catch(() => import('./pages/A/k-customer-attributes/ChangeOfWorkPlaceComplete')));
  const repaymentDateAnnouncementMailSample = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/RepaymentDateAnnouncementMailSample`).catch(() => import('./pages/A/k-customer-attributes/RepaymentDateAnnouncementMailSample')));
  const valuableInformationMailSample = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ValuableInformationMailSample`).catch(() => import('./pages/A/k-customer-attributes/ValuableInformationMailSample')));
  const changePhoneNumberInput = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangePhoneNumberInput`).catch(() => import('./pages/A/k-customer-attributes/ChangePhoneNumberInput')));
  const changePhoneNumberConfirm = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangePhoneNumberConfirm`).catch(() => import('./pages/A/k-customer-attributes/ChangePhoneNumberConfirm')));
  const changePhoneNumberComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangePhoneNumberComplete`).catch(() => import('./pages/A/k-customer-attributes/ChangePhoneNumberComplete')));
  const changeMailServiceInput = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeMailServiceInput`).catch(() => import('./pages/A/k-customer-attributes/ChangeMailServiceInput')));
  const changeMailServiceConfirm = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeMailServiceConfirm`).catch(() => import('./pages/A/k-customer-attributes/ChangeMailServiceConfirm')));
  const changeMailServiceComplete = loadable(() => import(`./pages/${viewPattern}/k-customer-attributes/ChangeMailServiceComplete`).catch(() => import('./pages/A/k-customer-attributes/ChangeMailServiceComplete')));
  // L
  const submissionOfIncomeCertificate = loadable(() => import(`./pages/${viewPattern}/l-income-certificate/SubmissionOfIncomeCertificate`).catch(() => import('./pages/A/l-income-certificate/SubmissionOfIncomeCertificate')));
  const submissionOfIncomeCertificateComplete = loadable(() => import(`./pages/${viewPattern}/l-income-certificate/SubmissionOfIncomeCertificateComplete`).catch(() => import('./pages/A/l-income-certificate/SubmissionOfIncomeCertificateComplete')));
  // M
  const accountAttributeTop = loadable(() => import(`./pages/${viewPattern}/m-account-attribute/AccountAttributeTop`).catch(() => import('./pages/A/m-account-attribute/AccountAttributeTop')));
  const webIDChangeInput = loadable(() => import(`./pages/${viewPattern}/m-account-attribute/WebIDChangeInput`).catch(() => import('./pages/A/m-account-attribute/WebIDChangeInput')));
  const webIDChangeComplete = loadable(() => import(`./pages/${viewPattern}/m-account-attribute/WebIDChangeComplete`).catch(() => import('./pages/A/m-account-attribute/WebIDChangeComplete')));
  const passwordChangeInput = loadable(() => import(`./pages/${viewPattern}/m-account-attribute/PasswordChangeInput`).catch(() => import('./pages/A/m-account-attribute/PasswordChangeInput')));
  const passwordChangeComplete = loadable(() => import(`./pages/${viewPattern}/m-account-attribute/PasswordChangeComplete`).catch(() => import('./pages/A/m-account-attribute/PasswordChangeComplete')));
  // O
  const cardStopInput = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/CardStopInput`).catch(() => import('./pages/A/o-card-suspension/CardStopInput')));
  const cardStopConfirmation = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/CardStopConfirmation`).catch(() => import('./pages/A/o-card-suspension/CardStopConfirmation')));
  const cardStopComplete = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/CardStopComplete`).catch(() => import('./pages/A/o-card-suspension/CardStopComplete')));
  const cardlessRegistrationConfirmation = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/CardlessRegistrationConfirmation`).catch(() => import('./pages/A/o-card-suspension/CardlessRegistrationConfirmation')));
  const cardlessCompleted = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/CardlessCompleted`).catch(() => import('./pages/A/o-card-suspension/CardlessCompleted')));
  const changePinInput = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/ChangePinInput`).catch(() => import('./pages/A/o-card-suspension/ChangePinInput')));
  const changePinComplete = loadable(() => import(`./pages/${viewPattern}/o-card-suspension/ChangePinComplete`).catch(() => import('./pages/A/o-card-suspension/ChangePinComplete')));
  // P
  const inquiryFormInput = loadable(() => import(`./pages/${viewPattern}/p-inquiry/InquiryFormInput`).catch(() => import('./pages/A/p-inquiry/InquiryFormInput')));
  const inquiryFormConfirm = loadable(() => import(`./pages/${viewPattern}/p-inquiry/InquiryFormConfirm`).catch(() => import('./pages/A/p-inquiry/InquiryFormConfirm')));
  const inquiryFormComplete = loadable(() => import(`./pages/${viewPattern}/p-inquiry/InquiryFormComplete`).catch(() => import('./pages/A/p-inquiry/InquiryFormComplete')));
  const pastInquiryHistory = loadable(() => import(`./pages/${viewPattern}/p-inquiry/PastInquiryHistory`).catch(() => import('./pages/A/p-inquiry/PastInquiryHistory')));
  const inquiryPhone = loadable(() => import(`./pages/${viewPattern}/p-inquiry/InquiryPhone`).catch(() => import('./pages/A/p-inquiry/InquiryPhone')));
  // Q
  const contractDetailsInformation = loadable(() => import(`./pages/${viewPattern}/q-contract-details-information/ContractDetailsInformation`).catch(() => import('./pages/A/q-contract-details-information/ContractDetailsInformation')));
  // S
  const depositReturnInput = loadable(() => import(`./pages/${viewPattern}/s-deposit/DepositReturnInput`).catch(() => import('./pages/A/s-deposit/DepositReturnInput')));
  const depositReturnConfirm = loadable(() => import(`./pages/${viewPattern}/s-deposit/DepositReturnConfirm`).catch(() => import('./pages/A/s-deposit/DepositReturnConfirm')));
  const depositReturnComplete = loadable(() => import(`./pages/${viewPattern}/s-deposit/DepositReturnComplete`).catch(() => import('./pages/A/s-deposit/DepositReturnComplete')));
  const noAccount = loadable(() => import(`./pages/${viewPattern}/s-deposit/NoAccount`).catch(() => import('./pages/A/s-deposit/NoAccount')));
  const noDeposit = loadable(() => import(`./pages/${viewPattern}/s-deposit/NoDeposit`).catch(() => import('./pages/A/s-deposit/NoDeposit')));
  const InCheckTerm = loadable(() => import(`./pages/${viewPattern}/s-deposit/InCheckTerm`).catch(() => import('./pages/A/s-deposit/InCheckTerm')));
  // U
  const ConfirmList = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ConfirmList')));
  const ConfirmApplication = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ConfirmApplication')));
  const ConfirmPaymentAccount = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ConfirmPaymentAccount')));
  const ConfirmContract = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ConfirmContract')));
  const CompleteContract = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/CompleteContract')));
  const ErrorNoAgree = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ErrorNoAgree')));
  const CollectionEdit = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/CollectionEdit')));
  const PreliminaryExamination = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/PreliminaryExamination')));
  const DifferenceApplication = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/DifferenceApplication')));
  const OverdueContract = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/OverdueContract')));
  const ProceduresAttribute = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/ProceduresAttribute')));
  const NowOverdue = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/NowOverdue')));
  const CalculatedPeriod = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/CalculatedPeriod')));
  const TemporaryLoanSuspension = loadable(() => import(`./pages/${viewPattern}/u-collection-loan`).catch(() => import('./pages/A/u-collection-loan/TemporaryLoanSuspension')));
  // W
  const accountInformation = loadable(() => import(`./pages/${viewPattern}/w-account-information/AccountInformation`).catch(() => import('./pages/A/w-account-information/AccountInformation')));
  // Z
  const error = loadable(() => import(`./pages/${viewPattern}/z-errors/Error`).catch(() => import('./pages/A/z-errors/Error')));
  const maintenance = loadable(() => import(`./pages/${viewPattern}/z-errors/Maintenance`).catch(() => import('./pages/A/z-errors/Maintenance')));

  // TT
  const loanTop = loadable(() => import(`./pages/${viewPattern}/tt-category-top/LoanTop`).catch(() => import('./pages/A/tt-category-top/LoanTop')));
  const proceduresTop = loadable(() => import(`./pages/${viewPattern}/tt-category-top/ProceduresTop`).catch(() => import('./pages/A/tt-category-top/ProceduresTop')));
  const repaymentTop = loadable(() => import(`./pages/${viewPattern}/tt-category-top/RepaymentTop`).catch(() => import('./pages/A/tt-category-top/RepaymentTop')));
  const supportTop = loadable(() => import(`./pages/${viewPattern}/tt-category-top/SupportTop`).catch(() => import('./pages/A/tt-category-top/SupportTop')));

  // X Appli
  const SPAppliGateway2 = loadable(() => import('./pages/x-login/SPAppliGateway2'));

  return (
    <>
      {/* Header */}
      <Route component={headerComponent} />
      <Switch>
        {/* A */}
        <AuthenticatedRoute exact path={urls.A001} component={contractDetails} />
        <AuthenticatedRoute exact path={urls.A002} component={downloadBasicContract} />
        {/* B */}
        <AuthenticatedRoute exact path={urls.B001} component={confirmNextRepaymentDate} />
        <AuthenticatedRoute exact path={urls.B002} component={repaymentScheduleInput} />
        <AuthenticatedRoute exact path={urls.B003} component={repaymentScheduleConfirm} />
        <AuthenticatedRoute exact path={urls.B004} component={repaymentScheduleComplete} />
        <AuthenticatedRoute exact path={urls.B012} component={noBalanceScreen} />
        <AuthenticatedRoute exact path={urls.B013} component={specificBlOrMultipleAccountScreen} />
        <AuthenticatedRoute exact path={urls.B015} component={payoffSimulation} />
        {/* C */}
        <AuthenticatedRoute exact path={urls.C001} component={updateContractDateInput} />
        <AuthenticatedRoute exact path={urls.C002} component={updateContractDateConfirm} />
        <AuthenticatedRoute exact path={urls.C004} component={contractNotAvailable} />
        <AuthenticatedRoute exact path={urls.C005} component={contractContinuationConfirm} />
        <AuthenticatedRoute exact path={urls.C007} component={contractReturnDeposit} />
        <AuthenticatedRoute exact path={urls.C010} component={updateContractDateComplete} />
        <AuthenticatedRoute exact path={urls.C011} component={littleLateRepayment} />
        <AuthenticatedRoute exact path={urls.C012} component={lateRepaymentMoreThan11days} />
        {/* D */}
        <AuthenticatedRoute exact path={urls.D001} component={screenInput} />
        <AuthenticatedRoute exact path={urls.D002} component={screenConfirmation} />
        <AuthenticatedRoute exact path={urls.D003} component={screenCompletion} />
        <AuthenticatedRoute exact path={urls.D004} component={screenDownload} />
        <AuthenticatedRoute exact path={urls.D005} component={sendUsageDetailsInput} />
        <AuthenticatedRoute exact path={urls.D006} component={sendUsageDetailsConfirm} />
        <AuthenticatedRoute exact path={urls.D007} component={sendUsageDetailsComplete} />
        <AuthenticatedRoute exact path={urls.D008} component={downloadAfterUnlocking} />
        <AuthenticatedRoute exact path={urls.D009} component={downloadAfterCancellation} />
        {/* E */}
        <AuthenticatedRoute exact path={urls.E001} component={borrowingInput} />
        <AuthenticatedRoute exact path={urls.E002} component={borrowingConfirm} />
        <AuthenticatedRoute exact path={urls.E003} component={borrowingComplete} />
        <AuthenticatedRoute exact path={urls.E004} component={unregisteredAccountError} />
        <AuthenticatedRoute exact path={urls.E005} component={requested} />
        <AuthenticatedRoute exact path={urls.E007} component={usageLimitTenThousandOrLess} />
        <AuthenticatedRoute exact path={urls.E008} component={lateRepayment} />
        <AuthenticatedRoute exact path={urls.E009} component={repaymentSimulation} />
        <AuthenticatedRoute exact path={urls.E016} component={depositExists} />
        {/* F */}
        <AuthenticatedRoute exact path={urls.F001} component={transferReservationInput} />
        <AuthenticatedRoute exact path={urls.F002} component={transferReservationConfirm} />
        <AuthenticatedRoute exact path={urls.F003} component={transferReservationComplete} />
        <AuthenticatedRoute exact path={urls.F004} component={cannotBeCanceled} />
        {/* G */}
        <AuthenticatedRoute exact path={urls.G001} component={immediateShakingRegisteredInput} />
        <AuthenticatedRoute exact path={urls.G002} component={immediateShakingRegisteredConfirm} />
        <AuthenticatedRoute exact path={urls.G003} component={immediateShakingRegisteredComplete} />
        {/* H */}
        <AuthenticatedRoute exact path={urls.H001} component={increaseTop} />
        <AuthenticatedRoute exact path={urls.H003} component={examinationErrorOffhours} />
        <AuthenticatedRoute exact path={urls.H004} component={examinationNg} />
        <AuthenticatedRoute exact path={urls.H005} component={examinationNgResisterMailAddressComplete} />
        <AuthenticatedRoute exact path={urls.H007} component={submitIncomeCertificate} />
        <AuthenticatedRoute exact path={urls.H009} component={increaseExaminationConfirm} />
        <AuthenticatedRoute exact path={urls.H010} component={workPlaceChangeRequest} />
        <AuthenticatedRoute exact path={urls.H012} component={changeContractInput} />
        <AuthenticatedRoute exact path={urls.H013} component={examinationErrorResult} />
        <AuthenticatedRoute exact path={urls.H014} component={changeContractConfirm} />
        <AuthenticatedRoute exact path={urls.H015} component={changeContractComplete} />
        <AuthenticatedRoute exact path={urls.H016} component={interestRateFixedAdditionalInput} />
        <AuthenticatedRoute exact path={urls.H017} component={limitAndInterestRateChangeInput} />
        <AuthenticatedRoute exact path={urls.H018} component={interestRateChangeAdditionalInput} />
        <AuthenticatedRoute exact path={urls.H019} component={interestRateChangeConfirm} />
        <AuthenticatedRoute exact path={urls.H020} component={limitAndInterestRateChangeConfirm} />
        <AuthenticatedRoute exact path={urls.H022} component={interestRateChangeCompleteBorrowable} />
        <AuthenticatedRoute exact path={urls.H023} component={limitAndInterestRateChangeComplete} />
        <AuthenticatedRoute exact path={urls.H025} component={examinationResultPassed} />
        <AuthenticatedRoute exact path={urls.H026} component={examinationResultPassedIncomeCertificateSelection} />
        <AuthenticatedRoute exact path={urls.H027} component={examinationResultPassedInquiryInformation} />
        <AuthenticatedRoute exact path={urls.H028} component={increaseContractDateChangeInformation} />
        <AuthenticatedRoute exact path={urls.H029} component={examinationResultOutOfService} />
        <AuthenticatedRoute exact path={urls.H030} component={examinationResultWaitingForChange} />
        <AuthenticatedRoute exact path={urls.H031} component={examinationResultPassedCanBeConsidered} />
        <AuthenticatedRoute exact path={urls.H032} component={examinationResultPassedIncreaseAndInteresRate} />
        <AuthenticatedRoute exact path={urls.H033} component={changeContractInputAnother} />
        <AuthenticatedRoute exact path={urls.H036} component={limitAndInterestRateChangeInputAnother} />
        <AuthenticatedRoute exact path={urls.H037} component={IncreaseForm} />

        {/* I */}
        <AuthenticatedRoute exact path={urls.I001} component={webRepaymentInput} />
        <AuthenticatedRoute exact path={urls.I002} component={webRepaymentConfirm} />
        <AuthenticatedRoute exact path={urls.I003} component={handedOverToFinancialInstitutions} />
        <AuthenticatedRoute exact path={urls.I004} component={noWebRepaymentBalance} />
        <AuthenticatedRoute exact path={urls.I006} component={webRepaymentMaintenance} />

        {/* J */}
        <AuthenticatedRoute exact path={urls.J001} component={automaticWithdrawalRegistration} />
        <AuthenticatedRoute exact path={urls.J002} component={confirmationOfWithdrawalDate} />
        <AuthenticatedRoute exact path={urls.J003} component={automaticWithdrawalApplicationAccountRegistration} />
        <AuthenticatedRoute exact path={urls.J004} component={mouthRegistrationComplete} />
        <AuthenticatedRoute exact path={urls.J005} component={mouthRegistrationNotComplete} />
        <AuthenticatedRoute exact path={urls.J006} component={mouthRegistered} />
        <AuthenticatedRoute exact path={urls.J007d} component={automaticWithdrawalChangeInput} />
        <AuthenticatedRoute exact path={urls.J008d} component={automaticWithdrawalDummy} />
        <AuthenticatedRoute exact path={urls.J012} component={confirmationOfCancellationOfMouthShakeRegistration} />
        <AuthenticatedRoute exact path={urls.J013} component={mouthRegistrationCancellationCompleted} />
        <AuthenticatedRoute exact path={urls.J014} component={registrationNotPossibleOutOfServiceCustomers} />
        <AuthenticatedRoute exact path={urls.J015} component={registrationNotPossibleOneDayDelay} />
        <AuthenticatedRoute exact path={urls.J016} component={registrationNotPossibleElevenDayDelay} />
        <AuthenticatedRoute exact path={urls.J017} component={registrationNotPossibleClaiming} />
        <AuthenticatedRoute exact path={urls.J018} component={RegistrationNotEnough} />
        {/* K */}
        <AuthenticatedRoute exact path={urls.K001} component={confirmationOfCustomerInformation} />
        <AuthenticatedRoute exact path={urls.K002} component={emailAddressChangeInput} />
        <AuthenticatedRoute exact path={urls.K003} component={emailAddressChangeConfirm} />
        <AuthenticatedRoute exact path={urls.K004} component={emailAddressChangeComplete} />
        <AuthenticatedRoute exact path={urls.K005} component={changeAddressPhoneNumberInput} />
        <AuthenticatedRoute exact path={urls.K006} component={changeAddressPhoneNumberConfirm} />
        <AuthenticatedRoute exact path={urls.K007} component={changeAddressPhoneNumberComplete} />
        <AuthenticatedRoute exact path={urls.K008} component={changeOfWorkPlaceInput} />
        <AuthenticatedRoute exact path={urls.K009} component={changeOfWorkPlaceConfirm} />
        <AuthenticatedRoute exact path={urls.K021} component={changeOfWorkPlaceComplete} />
        <AuthenticatedRoute exact path={urls.K024} component={repaymentDateAnnouncementMailSample} />
        <AuthenticatedRoute exact path={urls.K025} component={valuableInformationMailSample} />
        <AuthenticatedRoute exact path={urls.K026} component={changePhoneNumberInput} />
        <AuthenticatedRoute exact path={urls.K027} component={changePhoneNumberConfirm} />
        <AuthenticatedRoute exact path={urls.K028} component={changePhoneNumberComplete} />
        <AuthenticatedRoute exact path={urls.K029} component={confirmationOfCustomerInformationComplete} />
        <AuthenticatedRoute exact path={urls.K030} component={changeMailServiceInput} />
        <AuthenticatedRoute exact path={urls.K031} component={changeMailServiceConfirm} />
        <AuthenticatedRoute exact path={urls.K032} component={changeMailServiceComplete} />
        {/* L */}
        <AuthenticatedRoute exact path={urls.L001} component={submissionOfIncomeCertificate} />
        <AuthenticatedRoute exact path={urls.L002} component={submissionOfIncomeCertificateComplete} />
        {/* M */}
        <AuthenticatedRoute exact path={urls.M001} component={accountAttributeTop} />
        <AuthenticatedRoute exact path={urls.M002} component={webIDChangeInput} />
        <AuthenticatedRoute exact path={urls.M004} component={webIDChangeComplete} />
        <AuthenticatedRoute exact path={urls.M005} component={passwordChangeInput} />
        <AuthenticatedRoute exact path={urls.M006} component={passwordChangeComplete} />
        {/* O */}
        <AuthenticatedRoute exact path={urls.O001} component={cardStopInput} />
        <AuthenticatedRoute exact path={urls.O002} component={cardStopConfirmation} />
        <AuthenticatedRoute exact path={urls.O003} component={cardStopComplete} />
        <AuthenticatedRoute exact path={urls.O004} component={cardlessRegistrationConfirmation} />
        <AuthenticatedRoute exact path={urls.O005} component={cardlessCompleted} />
        <AuthenticatedRoute exact path={urls.O006} component={changePinInput} />
        <AuthenticatedRoute exact path={urls.O007} component={changePinComplete} />
        {/* P */}
        <AuthenticatedRoute exact path={urls.P002} component={inquiryFormInput} />
        <AuthenticatedRoute exact path={urls.P003} component={inquiryFormConfirm} />
        <AuthenticatedRoute exact path={urls.P004} component={inquiryFormComplete} />
        <AuthenticatedRoute exact path={urls.P005} component={pastInquiryHistory} />
        <AuthenticatedRoute exact path={urls.P006} component={inquiryPhone} />
        {/* Q */}
        <AuthenticatedRoute exact path={urls.Q001} component={contractDetailsInformation} />
        {/* S */}
        <AuthenticatedRoute exact path={urls.S001} component={depositReturnInput} />
        <AuthenticatedRoute exact path={urls.S002} component={depositReturnConfirm} />
        <AuthenticatedRoute exact path={urls.S003} component={depositReturnComplete} />
        <AuthenticatedRoute exact path={urls.S004} component={noAccount} />
        <AuthenticatedRoute exact path={urls.S005} component={noDeposit} />
        <AuthenticatedRoute exact path={urls.S006} component={InCheckTerm} />
        {/* T */}
        <AuthenticatedRoute exact path={urls.TOP} component={top} />
        <AuthenticatedRoute exact path={urls.T005} component={informationDetails} />
        {/* U */}
        <AuthenticatedRoute exact path={urls.U001} component={ConfirmList} />
        <AuthenticatedRoute exact path={urls.U002} component={ConfirmApplication} />
        <AuthenticatedRoute exact path={urls.U003} component={ConfirmPaymentAccount} />
        <AuthenticatedRoute exact path={urls.U004} component={ConfirmContract} />
        <AuthenticatedRoute exact path={urls.U005} component={CompleteContract} />
        <AuthenticatedRoute exact path={urls.U006} component={ErrorNoAgree} />
        <AuthenticatedRoute exact path={urls.U007} component={CollectionEdit} />
        <AuthenticatedRoute exact path={urls.U008} component={PreliminaryExamination} />
        <AuthenticatedRoute exact path={urls.U009} component={DifferenceApplication} />
        <AuthenticatedRoute exact path={urls.U010} component={OverdueContract} />
        <AuthenticatedRoute exact path={urls.U011} component={ProceduresAttribute} />
        <AuthenticatedRoute exact path={urls.U012} component={NowOverdue} />
        <AuthenticatedRoute exact path={urls.U013} component={CalculatedPeriod} />
        <AuthenticatedRoute exact path={urls.U014} component={TemporaryLoanSuspension} />
        {/* W */}
        <AuthenticatedRoute exact path={urls.W001} component={accountInformation} />
        {/* Z */}
        <Route exact path={urls.Z001} component={error} />
        <Route exact path={urls.Z003} component={maintenance} />
        {/* TT */}
        <AuthenticatedRoute exact path={urls.TT001} component={loanTop} />
        <AuthenticatedRoute exact path={urls.TT002} component={proceduresTop} />
        <AuthenticatedRoute exact path={urls.TT003} component={repaymentTop} />
        <AuthenticatedRoute exact path={urls.TT005} component={supportTop} />
        {/* X Appli */}
        <AuthenticatedRoute exact path={urls.SPAPPLI2} component={SPAppliGateway2} />
        {/* Not Found */}
        <Route component={error} />
      </Switch>
      {/* Footer */}
      <Route component={footerComponent} />
    </>
  );
};

export default PatternRoutes;
