/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  HType,
  StateType,
  ExaminationAfterType,
  ValidationErrorType,
  ExaminationNgValidationErrorType,
  ExaminationNgResisterMailResultType,
  ContractChangeValidationErrorType,
  ContractChangeResultType,
  InteresRateChangeType,
  ExaminationAfterInfoType,
  RepaymentTableType,
} from './types';

// 初期値
const initialState: StateType = {
  top: {
    会員番号: '',
    口座枝番: '',
    現在のご利用限度額: '',
    検討可能なご利用限度額: '',
    収入証明書類を提出いただいた場合: '',
    お客さまのご提出状況: '',
    提出済みの収入証明書類: '',
    受領日: '',
    有効期限: '',
    有効期限フラグ: '',
    証明書受領状況区分: '',
    理由: '',
    ご自宅住所: '',
    お勤め先: '',
    メールアドレス: '',
    営業開始時間: '',
    営業終了時間: '',
    営業時間外フラグ: '',
    みなし期間中フラグ: '',
    延滞中フラグ: '',
    ALSA商品かつ一部融停フラグ: '',
    次画面ID: '',
  },
  // 審査後
  examinationAfter: {
    会員番号: '',
    口座枝番: '',
    お勤め先: '',
    お得な情報案内メール: '',
    ご案内メール送付先: '',
    ご自宅住所: '',
    家賃住宅ローン: '',
    検討可能なご利用限度額: '',
    現在のご利用限度額: '',
    現在の貸付利率: '',
    現在の遅延損害金利率: '',
    受付完了メール送付先: '',
    受領日: '',
    収入証明書類を提出いただいた場合: '',
    税込年収: '',
    増額可能なご利用限度額: '',
    他社お借入れ総額: '',
    提出済みの収入証明書類: '',
    変更後の遅延損害金利率: '',
    変更後の貸付利率: '',
    本人を除く同居人数: '',
    有効期限: '',
    次画面ID: '',
    ＤＰステータス: '',
    証明書受領状況区分: '',
    お客さまのご提出状況: '',
    理由: '',
    有効期限フラグ: '',
  },
  validationError: {
    税込年収: '',
    家賃住宅ローン: '',
    本人を除く同居人数: '',
    他社お借入れ総額: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  examinationNgResisterMailResult: {
    会員番号: '',
    メールアドレス: '',
    お得情報案内メール: '',
  },
  examinationNgValidationError: {
    メールアドレス: '',
    メールアドレス確認: '',
    お得情報案内メール: '',
  },
  // 共通
  isChangeMailAddress: false,
  inputCompletionDeliveryMailAddress: '',
  inputCompletionDeliveryMailAddressConfirm: '',
  // Top(審査)入力
  inputYearlyIncome: '',
  inputHousingLoan: '',
  inputHousemateNumber: '',
  inputBorrowingsAmount: '',
  // 審査NG
  inputAdvantageousInformationEmail: '0',
  // 契約変更内容入力
  inputContractAmount: '',
  inputContractDestination: 'A',
  inputIntentionConfirm: '2',
  contractChangeValidationError: {
    ご希望限度額: '',
    団信区分: '',
    契約書送付先区分: '',
    メールアドレス: '',
    メールアドレス確認: '',
  },
  contractChangeResult: {
    会員番号: '',
    口座枝番: '',
    変更契約額: '',
    契約書送付先区分: '',
    メールアドレス: '',
    団信区分: '',
    検討可能なご利用限度額: '',
    現在のご利用限度額: '',
    ＴＣ口座: '',
    連番: '',
    契約変更年月日: '',
  },
  // 利率変更初期処理
  interesRateChange: {
    会員番号: '',
    口座枝番: '',
    ご自宅: '',
    お勤め先: '',
    メールアドレス: '',
    団信区分: '',
    現在の貸付利率: '',
    現在の遅延損害金利率: '',
    変更後の貸付利率: '',
    変更後の遅延損害金利率: '',
    利率変動区分: '',
  },
  // 審査後入力画面の必要情報
  examinationAfterInfo: {
    会員番号: '',
    口座枝番: '',
    現在のご利用限度額: '',
    増額可能なご利用限度額: '',
    団信区分: '',
    ご自宅: '',
    お勤め先: '',
    メールアドレス: '',
  },
  confirmMailAddress: '',
  repaymentTable: {
    '100万円未満＿貸付利率': '',
    '100万円以上＿貸付利率': '',
    毎月返済額: [],
    定額返済額: '0',
    定額ボーナス返済額: '0',
  },
  repaymentInfo: {
    返済額: '',
    基準貸付残高: '',
  },
  repaymentScheduleChangeFlag: '',
  limitedPrice: '0',
  isFixedRate: 0,
  isBonus: 0,
  fixedRate: '0',
  bonus: '0',
};

const slice = createSlice({
  name: 'h',
  initialState,
  reducers: {
    // TOP
    updateTop: (state: StateType, action: PayloadAction<HType>) => {
      state.top = action.payload;
    },
    // TOP入力
    updateYearlyIncome: (state: StateType, action: PayloadAction<string>) => {
      state.inputYearlyIncome = action.payload;
    },
    updateHousingLoan: (state: StateType, action: PayloadAction<string>) => {
      state.inputHousingLoan = action.payload;
    },
    updateHousemateNumber: (state: StateType, action: PayloadAction<string>) => {
      state.inputHousemateNumber = action.payload;
    },
    updateBorrowingsAmount: (state: StateType, action: PayloadAction<string>) => {
      state.inputBorrowingsAmount = action.payload;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputCompletionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    clearInputValues: (state: StateType) => {
      state.inputYearlyIncome = initialState.inputYearlyIncome;
      state.inputHousingLoan = initialState.inputHousingLoan;
      state.inputHousemateNumber = initialState.inputHousemateNumber;
      state.inputBorrowingsAmount = initialState.inputBorrowingsAmount;
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    // 審査通過後
    updateExaminationAfter: (state: StateType, action: PayloadAction<ExaminationAfterType>) => {
      state.examinationAfter = action.payload;
    },
    // 審査NG
    updateExaminationNgResisterMailResult: (state: StateType, action: PayloadAction<ExaminationNgResisterMailResultType>) => {
      state.examinationNgResisterMailResult = action.payload;
    },
    updateAdvantageousInformationEmail: (state: StateType, action: PayloadAction<string>) => {
      state.inputAdvantageousInformationEmail = action.payload;
    },
    clearInputExaminationNgValues: (state: StateType) => {
      state.inputAdvantageousInformationEmail = initialState.inputAdvantageousInformationEmail;
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    updateExaminationNgValidationError: (state: StateType, action: PayloadAction<ExaminationNgValidationErrorType>) => {
      state.examinationNgValidationError = action.payload;
    },
    clearExaminationNgValidationError: (state: StateType) => {
      state.examinationNgValidationError = initialState.examinationNgValidationError;
    },
    // 契約変更内容入力
    updateContractAmount: (state: StateType, action: PayloadAction<string>) => {
      state.inputContractAmount = action.payload;
    },
    updateContractDestination: (state: StateType, action: PayloadAction<string>) => {
      state.inputContractDestination = action.payload;
    },
    updateIntentionConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.inputIntentionConfirm = action.payload;
    },
    updateContractChangeValidationError: (state: StateType, action: PayloadAction<ContractChangeValidationErrorType>) => {
      state.contractChangeValidationError = action.payload;
    },
    clearContractChangeValidationError: (state: StateType) => {
      state.contractChangeValidationError = initialState.contractChangeValidationError;
    },
    clearInputContractChangeValues: (state: StateType) => {
      state.inputContractAmount = initialState.inputContractAmount;
      state.inputContractDestination = initialState.inputContractDestination;
      state.inputIntentionConfirm = initialState.inputIntentionConfirm;
      state.inputCompletionDeliveryMailAddress = initialState.inputCompletionDeliveryMailAddress;
      state.inputCompletionDeliveryMailAddressConfirm = initialState.inputCompletionDeliveryMailAddressConfirm;
      state.isChangeMailAddress = initialState.isChangeMailAddress;
    },
    updateContractChangeResult: (state: StateType, action: PayloadAction<ContractChangeResultType>) => {
      state.contractChangeResult = action.payload;
    },
    // 利率変更初期処理
    updateInteresRateChange: (state: StateType, action: PayloadAction<InteresRateChangeType>) => {
      state.interesRateChange = action.payload;
    },
    // 審査後入力画面の必要情報
    updateExaminationAfterInfo: (state: StateType, action: PayloadAction<ExaminationAfterInfoType>) => {
      state.examinationAfterInfo = action.payload;
    },
    updateConfirmMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.confirmMailAddress = action.payload;
    },
    // 返済金額表
    updateRepaymentTable: (state: StateType, action: PayloadAction<RepaymentTableType>) => {
      state.repaymentTable = action.payload;
    },
    updateRepaymentScheduleChangeFlag: (state: StateType, action: PayloadAction<string>) => {
      state.repaymentScheduleChangeFlag = action.payload;
    },
    updateLimitedPrice: (state: StateType, action: PayloadAction<string>) => {
      state.limitedPrice = action.payload;
    },
    updateSetIsFixedRate: (state: StateType, action: PayloadAction<Number>) => {
      state.isFixedRate = action.payload;
    },
    updateIsBonus: (state: StateType, action: PayloadAction<Number>) => {
      state.isBonus = action.payload;
    },
    updateFixedRate: (state: StateType, action: PayloadAction<string>) => {
      state.fixedRate = action.payload;
    },
    updateBonus: (state: StateType, action: PayloadAction<string>) => {
      state.bonus = action.payload;
    },
  },
});

export default slice;
