/* eslint-disable no-param-reassign */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  DType,
  WebStatementRegistrationType,
  WebStatementServiceSuspensionType,
  WebStatementServiceCompleteType,
  WebStatementCancellationInfoType,
  ValidationErrorType,
  StateType,
  TopType,
  BankInfoType,
  InformationType,
} from './types';

// 初期値
const initialState: StateType = {
  webStatement: {
    会員番号: '',
    月次計算書交付区分: '',
    メールアドレス: '',
    前月明細ダウンロード可能フラグ: '',
    処理日時: '',
  },
  webStatementRegistration: {
    会員番号: '',
    メールアドレス: '',
    ダウンロード開始日: '',
  },
  webStatementServiceSuspension: {
    会員番号: '',
    自宅住所漢字: '',
    勤務先住所漢字: '',
  },
  webStatementServiceComplete: {
    会員番号: '',
    口座枝番: '',
    メールアドレス: '',
    メールアドレス変更区分: '',
    即振対象: '',
    ダウンロード開始日: '',
  },
  webStatementCancellationInfo: {
    会員番号: '',
    前月明細ダウンロード可能フラグ: '',
    処理日時: '',
  },
  validationError: {
    メールアドレス: '',
    メールアドレス確認: '',
    送付先区分: '',
    ご利用明細: [
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
      {
        対象年月: '',
        BASE64: '',
      },
    ],
  },
  completionDeliveryMailAddress: '',
  completionDeliveryMailAddressConfirm: '',
  isChangeMailAddress: false,
  inputStatementMailingAddress: '1',
  top: {
    会員番号: '',
    勤務先区分: '',
    カード発行状況: '',
    ＴＣ口座登録有無: '',
    ABテストパターン判定: '',
    重要なお知らせの最終更新日時: '',
    お知らせの最終更新日時: '',
    新着メッセージ有無区分: '',
    融資一時停止区分: '',
    sn: '',
    メッセージ重要: '',
    メッセージ重要遷移先機能: '',
    メッセージ重要ボタン名: '',
    口座情報: [],
    処理日時: '',
  },
  selectedBankInfo: {
    口座枝番: '',
    商品名: '',
    無利息期間: '',
    利用可能額: '',
    残高: '',
    次回支払年月日: '',
    自振現状区分: '',
    自振状況区分: '',
    自振登録状況: '',
    毎回額: '',
    返済金額: '',
    メッセージ帯: '',
    メッセージＡ１タイトル: '',
    メッセージＡ１: '',
    メッセージＡ１遷移先機能: '',
    メッセージＡ１ボタン名: '',
    メッセージＡ２タイトル: '',
    メッセージＡ２: '',
    メッセージＡ２遷移先機能: '',
    メッセージＡ２ボタン名: '',
    メッセージＢ: '',
    メッセージＢ遷移先機能: '',
    メッセージＢボタン名: '',
    カード発行区分: '',
    解約区分: '',
  },
  informations: {
    重要なお知らせ更新日時: '',
    重要なお知らせ内容: [],
    お知らせ更新日時: '',
    お知らせ内容: [],
  },
};

const slice = createSlice({
  name: 'd',
  initialState,
  reducers: {
    updateWebStatement: (state: StateType, action: PayloadAction<DType>) => {
      state.webStatement = action.payload;
    },
    updateWebStatementRegistration: (state: StateType, action: PayloadAction<WebStatementRegistrationType>) => {
      state.webStatementRegistration = action.payload;
    },
    updateWebStatementServiceSuspension: (state: StateType, action: PayloadAction<WebStatementServiceSuspensionType>) => {
      state.webStatementServiceSuspension = action.payload;
    },
    updateWebStatementServiceComplete: (state: StateType, action: PayloadAction<WebStatementServiceCompleteType>) => {
      state.webStatementServiceComplete = action.payload;
    },
    updateWebStatementCancellationInfo: (state: StateType, action: PayloadAction<WebStatementCancellationInfoType>) => {
      state.webStatementCancellationInfo = action.payload;
    },
    updateValidationError: (state: StateType, action: PayloadAction<ValidationErrorType>) => {
      state.validationError = action.payload;
    },
    clearValidationError: (state: StateType) => {
      state.validationError = initialState.validationError;
    },
    updateCompletionDeliveryMailAddress: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddress = action.payload;
    },
    updateCompletionDeliveryMailAddressConfirm: (state: StateType, action: PayloadAction<string>) => {
      state.completionDeliveryMailAddressConfirm = action.payload;
    },
    updateIsChangeMailAddress: (state: StateType) => {
      state.isChangeMailAddress = !state.isChangeMailAddress;
    },
    updateStatementMailingAddress: (state: StateType, action: PayloadAction<string>) => {
      state.inputStatementMailingAddress = action.payload;
    },
    updateCurrentTopInfo: (state: StateType, action: PayloadAction<TopType>) => {
      state.top = action.payload;
    },
    updateSelectedBankInfo: (state: StateType, action: PayloadAction<BankInfoType>) => {
      state.selectedBankInfo = action.payload;
    },
    updateInformations: (state: StateType, action: PayloadAction<InformationType>) => {
      state.informations = action.payload;
    },
  },
});

export default slice;
