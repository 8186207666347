import urls from './urls';

/**
 * 各ページのIDを定義。
 */
export const pageIds = {
  [urls.LOGIN]: 'X001',
  [urls.X002]: 'X002',
  [urls.X003]: 'X003',
  [urls.X004]: 'X004',
  [urls.X005]: 'X005',
  [urls.X006]: 'X006',
  [urls.X008]: 'X008',
  [urls.X010]: 'X010',
  [urls.X011]: 'X011',
  [urls.X017]: 'X017',
  [urls.TOP]: 'T001',
  [urls.T005]: 'T005',
  [urls.A001]: 'A001',
  [urls.A002]: 'A002',
  [urls.B001]: 'B001',
  [urls.B002]: 'B002',
  [urls.B003]: 'B003',
  [urls.B004]: 'B004',
  [urls.B005]: 'B005',
  [urls.B006]: 'B006',
  [urls.B008]: 'B008',
  [urls.B009]: 'B009',
  [urls.B010]: 'B010',
  [urls.B012]: 'B012',
  [urls.B013]: 'B013',
  [urls.B015]: 'B015',
  [urls.C001]: 'C001',
  [urls.C002]: 'C002',
  [urls.C004]: 'C004',
  [urls.C005]: 'C005',
  [urls.C006]: 'C006',
  [urls.C007]: 'C007',
  [urls.C010]: 'C010',
  [urls.C011]: 'C011',
  [urls.C012]: 'C012',
  [urls.D001]: 'D001',
  [urls.D002]: 'D002',
  [urls.D003]: 'D003',
  [urls.D004]: 'D004',
  [urls.D005]: 'D005',
  [urls.D006]: 'D006',
  [urls.D007]: 'D007',
  [urls.D008]: 'D008',
  [urls.D009]: 'D009',
  [urls.E001]: 'E001',
  [urls.E002]: 'E002',
  [urls.E003]: 'E003',
  [urls.E004]: 'E004',
  [urls.E005]: 'E005',
  [urls.E007]: 'E007',
  [urls.E008]: 'E008',
  [urls.E009]: 'E009',
  [urls.E016]: 'E016',
  [urls.F001]: 'F001',
  [urls.F002]: 'F002',
  [urls.F003]: 'F003',
  [urls.F004]: 'F004',
  [urls.G001]: 'G001',
  [urls.G002]: 'G002',
  [urls.G003]: 'G003',
  [urls.H001]: 'H001',
  [urls.H002]: 'H002',
  [urls.H003]: 'H003',
  [urls.H004]: 'H004',
  [urls.H005]: 'H005',
  [urls.H006]: 'H006',
  [urls.H007]: 'H007',
  [urls.H008]: 'H008',
  [urls.H009]: 'H009',
  [urls.H010]: 'H010',
  [urls.H012]: 'H012',
  [urls.H013]: 'H013',
  [urls.H014]: 'H014',
  [urls.H015]: 'H015',
  [urls.H015]: 'H016',
  [urls.H015]: 'H017',
  [urls.H015]: 'H018',
  [urls.H015]: 'H019',
  [urls.H015]: 'H020',
  [urls.H015]: 'H022',
  [urls.H015]: 'H023',
  [urls.H015]: 'H025',
  [urls.H015]: 'H026',
  [urls.H015]: 'H027',
  [urls.H015]: 'H028',
  [urls.H015]: 'H029',
  [urls.H015]: 'H030',
  [urls.H015]: 'H031',
  [urls.H015]: 'H032',
  [urls.H015]: 'H033',
  [urls.H015]: 'H036',
  [urls.H037]: 'H037',
  [urls.I001]: 'I001',
  [urls.I002]: 'I002',
  [urls.I003]: 'I003',
  [urls.I004]: 'I004',
  [urls.I006]: 'I006',
  [urls.J001]: 'J001',
  [urls.J002]: 'J002',
  [urls.J003]: 'J003',
  [urls.J004]: 'J004',
  [urls.J005]: 'J005',
  [urls.J006]: 'J006',
  [urls.J007d]: 'J007d',
  [urls.J008d]: 'J008d',
  [urls.J012]: 'J012',
  [urls.J013]: 'J013',
  [urls.J014]: 'J014',
  [urls.J015]: 'J015',
  [urls.J016]: 'J016',
  [urls.J017]: 'J017',
  [urls.K001]: 'K001',
  [urls.K002]: 'K002',
  [urls.K003]: 'K003',
  [urls.K004]: 'K004',
  [urls.K005]: 'K005',
  [urls.K006]: 'K006',
  [urls.K007]: 'K007',
  [urls.K008]: 'K008',
  [urls.K009]: 'K009',
  [urls.K021]: 'K021',
  [urls.K024]: 'K024',
  [urls.K025]: 'K025',
  [urls.K026]: 'K026',
  [urls.K027]: 'K027',
  [urls.K028]: 'K028',
  [urls.K029]: 'K029',
  [urls.K030]: 'K030',
  [urls.K031]: 'K031',
  [urls.K032]: 'K032',
  [urls.L001]: 'L001',
  [urls.L002]: 'L002',
  [urls.M001]: 'm001',
  [urls.M002]: 'm002',
  [urls.M004]: 'm004',
  [urls.M005]: 'm005',
  [urls.M006]: 'm006',
  [urls.O001]: 'O001',
  [urls.O002]: 'O002',
  [urls.O003]: 'O003',
  [urls.O004]: 'O004',
  [urls.O005]: 'O005',
  [urls.O006]: 'O006',
  [urls.O007]: 'O007',
  [urls.P002]: 'P002',
  [urls.P003]: 'P003',
  [urls.P004]: 'P004',
  [urls.P005]: 'P005',
  [urls.P006]: 'P006',
  [urls.Q001]: 'Q001',
  [urls.S001]: 'S001',
  [urls.S002]: 'S002',
  [urls.S003]: 'S003',
  [urls.S004]: 'S004',
  [urls.S006]: 'S006',
  [urls.U001]: 'U001',
  [urls.U002]: 'U002',
  [urls.U003]: 'U003',
  [urls.U004]: 'U004',
  [urls.U005]: 'U005',
  [urls.U006]: 'U006',
  [urls.U007]: 'U007',
  [urls.U008]: 'U008',
  [urls.U009]: 'U009',
  [urls.U010]: 'U010',
  [urls.U011]: 'U011',
  [urls.U012]: 'U012',
  [urls.U013]: 'U013',
  [urls.U014]: 'U014',
  [urls.W001]: 'W001',
  [urls.Z001]: 'Z001',
  [urls.Z003]: 'Z003',
  [urls.TT001]: 'TT001',
  [urls.TT002]: 'TT002',
  [urls.TT003]: 'TT003',
  [urls.TT005]: 'TT005',
};

export default pageIds;

/**
 * 画面IDを取得する。
 * 環境変数「REACT_APP_SHOW_PAGE_ID」の設定に依存する。
 * REACT_APP_SHOW_PAGE_IDが「true」の場合に画面IDを返す。
 * それ以外は「null」を返す。
 * @param url 画面URL
 * @returns 画面ID
 */
export function getPageIdIfNecessary(url: string) {
  if (process.env.REACT_APP_SHOW_PAGE_ID !== 'true') return null;
  return pageIds[url];
}
